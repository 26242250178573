import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../App.css";
import SectionWipes from "./SectionWipes";
import Footer from "../Footer";
import Modal from "react-modal";
import Newsletter from "./Newsletter";
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const HomeComponents = () => {

  const { t } = useTranslation();
  const [isActiveAgri, setIsActiveAgri] = useState(false);
  const [isActiveReal, setIsActiveReal] = useState(false);
  const [isActiveMedical, setIsActiveMedical] = useState(false);


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenAgri, setModalIsOpenAgri] = useState(false);
  const [modalIsOpenMedical, setModalIsOpenMedical] = useState(false);

  const handleClickAgri = () => { 
    setIsActiveAgri(true);
  
   
  };

  const handleClickReal = () => { 
    setIsActiveReal(true);
   
    
  };
  
  const handleClickMedical = () => { 
    setIsActiveMedical(true);
   
   
  };

  const handlePopoverCloseReal = () => {
    setIsActiveReal(current => !current);


    if (!isActiveReal) {
      document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
  } else {
      document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
  }
};

const handlePopoverCloseAgri = () => {
setIsActiveAgri(current => !current);

if (!isActiveAgri) {
  document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
} else {
  document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
}
};
const handlePopoverCloseMedical = () => {
setIsActiveMedical(current => !current);

if (!isActiveMedical) {
document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
} else {
document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
}
};

 
  const popoverAgriculture = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" >{t("app.agriculture")}</Popover.Header>
      <Popover.Body >
      <div className="text" ><img src="/images/x.svg" alt="" width={30} height={30}/></div>
      {t("app.agricultureDesc")}
      </Popover.Body>
    </Popover>
  );

  

  
  const popoverRealestate = (
    <Popover id="popover-basic" >
      <Popover.Header as="h3" >{t("app.realEstate")}</Popover.Header>
      <Popover.Body >
      <div className="text" ><img src="/images/x.svg" alt="" width={30} height={30}/></div>
      
      {t("app.realEstateDesc")}
      </Popover.Body>
    </Popover>
  );
  
  
  const popoverMedical = (
    <Popover id="popover-basic" >
      <Popover.Header as="h3"   >{t("app.medical")}</Popover.Header>
      <Popover.Body >
      <div className="text"  ><img src="/images/x.svg" alt="" width={30} height={30}/></div>
      {t("app.medicalDesc")}
      </Popover.Body>
    </Popover>
  );



  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  

    return (
      <div>
 {windowSize[0] > 913 ? 
  <>
         <div className="text-center home-gallery-area2">
      <div
        className={
          isActiveReal ? "blur home-gallery-img real-estate " : "normal home-gallery-img real-estate"
        }
      >
        <div className="real-estate-action">
          <h1 className="home-gallery-area-header" style={{ opacity: isActiveReal ? "0" : "1" }}>
          {t("app.realEstate")}
          </h1>
          <OverlayTrigger
            trigger="click"
            placement="top-start"
            overlay={popoverRealestate}
            delay="2"
            onExited={handlePopoverCloseReal}
            rootClose
          >
            <Button className="home-gallery-area-button" onClick={handleClickReal}>
            {t("app.discover")}
            </Button>
          </OverlayTrigger>
        </div>
      </div>
    </div>

    <div className="text-center home-gallery-area2">
                <div  className={isActiveAgri ? "blur home-gallery-img agriculture": "normal home-gallery-img agriculture"}>
                <div className="agriculture-action">
                    <h1 className="home-gallery-area-header "  style={{ opacity: isActiveAgri ? '0' : '1' }}> {t("app.agriculture")}</h1>
                    <OverlayTrigger trigger="click" placement="top-start" overlay={popoverAgriculture}  onExited={handlePopoverCloseAgri}
            rootClose delay="2">
                      <Button  className="home-gallery-area-button"
                          onClick={handleClickAgri} >{t("app.discover")}</Button>
                    </OverlayTrigger>
                    </div>
                </div>
         </div>
         <div className="text-center home-gallery-area2">
                <div   className={isActiveMedical ? "blur home-gallery-img medical": "normal home-gallery-img medical"}>
                <div className="medical-action">
                    <h1 className="home-gallery-area-header "  style={{ opacity: isActiveMedical ? '0' : '1' }}> {t("app.medical")}</h1>
                    <OverlayTrigger trigger="click" placement="top-start" overlay={popoverMedical}  onExited={handlePopoverCloseMedical}
            rootClose delay="2">
                      <Button  className="home-gallery-area-button"
                          onClick={handleClickMedical} >{t("app.discover")}</Button>
                    </OverlayTrigger>
                    </div>
                </div>
         </div>
  
  </>
:  
  <><div className="text-center home-gallery-area2">
                <img src="/images/realEstate3.jpeg" alt=""  className={isActiveReal ? "blur home-gallery-img": "normal home-gallery-img"}/>
                <div className="home-gallery-text-area-first">
                    <h1 className="home-gallery-area-header "  style={{ opacity: isActiveReal ? '0' : '1' }}> {t("app.realEstate")}</h1>
                    <button onClick={() => setModalIsOpen(true)} className="home-gallery-area-button"> {t("app.discover")}</button>
                    <Modal isOpen={modalIsOpen} className="popup-realestate popup">
                    <button onClick={() => setModalIsOpen(false)} className="close-button">X</button>
                    <h2 className="title-popup">{t("app.realEstate")}</h2>
                    <p> {t("app.realEstateDesc")}</p>
                  </Modal>
                    
                </div>
         </div>
         <div className="text-center home-gallery-area2">
                <img src="/images/agriculture2.png" alt=""  className={isActiveAgri ? "blur home-gallery-img": "normal home-gallery-img"}/>
                <div className="home-gallery-text-area2">
                    <h1 className="home-gallery-area-header "  style={{ opacity: isActiveAgri ? '0' : '1' }}> {t("app.agriculture")}</h1>
                    <button onClick={() => setModalIsOpenAgri(true)} className="home-gallery-area-button"> {t("app.discover")}</button>
                    <Modal isOpen={modalIsOpenAgri} className="popup-agri popup">
                    <button onClick={() => setModalIsOpenAgri(false)} className="close-button">X</button>
                    <h2 className="title-popup">{t("app.agriculture")}</h2>
                    <p> {t("app.agricultureDesc")}</p>
                  </Modal>
                </div>
         </div>
         <div className="text-center home-gallery-area2">
                <img src="/images/medical.jpg" alt=""  className={isActiveMedical ? "blur home-gallery-img": "normal home-gallery-img"}/>
                <div className="home-gallery-text-area2">
                    <h1 className="home-gallery-area-header "  style={{ opacity: isActiveMedical ? '0' : '1' }}> {t("app.medical")}</h1>
                    <button onClick={() => setModalIsOpenMedical(true)} className="home-gallery-area-button"> {t("app.discover")}</button>
                    <Modal isOpen={modalIsOpenMedical} className="popup-medical popup">
                    <button onClick={() => setModalIsOpenMedical(false)} className="close-button">X</button>
                    <h2 className="title-popup">{t("app.medical")}</h2>
                    <p>  {t("app.medicalDesc")}</p>
                  </Modal>
                </div>
         </div>
         </>
}
         <Newsletter />
         <Footer/>
    

</div>
    );
};

export default HomeComponents;
