import React from "react";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import Newsletter from "./Newsletter";
import ImageOne from './ImageOne';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
const About = () => {
  const { t } = useTranslation();
  return (
  <>
   <div className="previous-page" id="about"> 
      <div className="about-image">
        <h1 className="about-title">{t("app.history")}</h1>
      </div>
      <div className="history-area" >
    
      <div >
            <div className="previous-desc">
              <p >{t("app.previousWorkDesc")}</p>
              <a href="http://smougroup.ae" className="previous-link">http://smougroup.ae</a>
            </div>
        </div>
      <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
           // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="2007"
            iconStyle={{ background: '#F6CC04', color: '#fff' }}
          
          >
            <h3 className="vertical-timeline-element-title">{t("app.firstTitle")}</h3>
          
            <p>
            {t("app.firstDesc")}
            </p>
          </VerticalTimelineElement>
          
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2008"
            iconStyle={{ background: '#839910', color: '#fff' }}
          
          >
            <h3 className="vertical-timeline-element-title">  {t("app.secondTitle")}</h3>
            <p>
            {t("app.secondDesc")}
            </p>
          </VerticalTimelineElement>
          
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2011"
            iconStyle={{ background: '#F6CC04', color: '#fff' }}
           
          >
            <h3 className="vertical-timeline-element-title">  {t("app.thirdTitle")}</h3>
            <p>
            {t("app.thirdDesc")}           
            </p>
          </VerticalTimelineElement>
          
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2022"
            iconStyle={{ background: '#839910', color: '#fff' }}          
          >
            <h3 className="vertical-timeline-element-title"> {t("app.fourTitle")}</h3>
            <p>
            {t("app.fourDesc")}
            </p>
          </VerticalTimelineElement>
          
        
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2023"
            iconStyle={{ background: '#F6CC04', color: '#fff' }}          
          >
            <h3 className="vertical-timeline-element-title"> {t("app.fiveTitle")}</h3>
            <p>
            {t("app.fiveDesc")}
            </p>
          </VerticalTimelineElement>
          
        
         

      </VerticalTimeline>
      </div>
  </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default About;

/*
<div>
          <img src="/images/person.png" alt="" className="about-ceo-image" />
        </div> 

          <h2 className="history-title">{t("app.history")}</h2>
*/
