import React from "react";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import Newsletter from "./Newsletter";


const Ceo= ()=> {
  const { t } = useTranslation();

  return (
    <section id="ceo">
       <div className="ceo-image">
        <h1 className="about-title">{t("app.ceoMessageTitle")}</h1>
      </div>
    <div className="about-ceo-area" >
      <div className="about-ceo-area-inside">
       
        <div>
        <img src="/images/ceo.jpeg" alt="" className="about-ceo-image" />
      </div> 
        <div className="about-desc">
          <p >{t("app.ceoMessageDesc")}</p>
        </div>
      </div>

    </div>
    <Newsletter />
      <Footer />
    </section>
    
  );
}

export default Ceo;

/*
    <h4 className="ceo-title">{t("app.ceoMessageTitle")}</h4>

*/