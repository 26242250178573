import React from "react";
import "../../App.css";
import { useTranslation } from "react-i18next";
import GoogleMapReact from 'google-map-react';
import Footer from "../Footer";
import Newsletter from "./Newsletter";


const AnyReactComponent = ({ text }) => <>  <i className="fa fa-map-marker marker-style" /> <div className="marker-text">{text}</div> </>;

const Contact = () => {
  const { t } = useTranslation();

  const defaultProps = {
    center: {
      lat: 41.119453,
      lng: 28.774940
    },
    zoom: 16
  };

  function handlePhoneNumberClick(phoneNumber) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      window.location.href = `tel:${phoneNumber}`;
    }
  }
  
  
  
  return ( 
  <>  
    <div className="text-center contact-area" id="contact" >
    <div className="contact-image" id="map">
        <h1 className="about-title">{t("app.getIn")}</h1>
      </div>


       
        <div className="map" >
              <GoogleMapReact
               bootstrapURLKeys={{ key: "AIzaSyBPM305VtylOGnu8Je-QjbSI0N6WlIACSM" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent

                  lat={41.119453}
                  lng={28.774940}
                  text="TİGAYA OFFICE"
                />
              </GoogleMapReact>

              
        </div>

        <div className="contact-adres-area" id="address">
          <div className="contact-adres">
            <h3 className="contact-adres-area-title"> {t("app.adresTitle1")}</h3>
            <div className="contact-adres-area-desc">{t("app.adresDesc1")}</div>
             <br />
             <div className="contact-adres-area-desc"><b>{t("app.contactDetails")}</b></div>
            <div className="contact-adres-area-desc">
           <span> {t("app.phone")}</span>
            <span  className="contact-adres-area-desc-phone" onClick={() => handlePhoneNumberClick(t("app.adresDesc11number"))}>   {t("app.adresDesc11number")}</span> 
         <span>   {t("app.adresDesc11")}</span>
          </div>

          </div>

          <div  className="contact-adres">
            <h3 className="contact-adres-area-title">{t("app.adresTitle2")}</h3>
            <div  className="contact-adres-area-desc">{t("app.adresDesc2")}</div> 
            <br/>
            <div  className="contact-adres-area-desc"><b>{t("app.contactDetails")}</b></div> 
            <div  className="contact-adres-area-desc">
            <span>{t("app.phone")}</span>
            <span  className="contact-adres-area-desc-phone" onClick={() => handlePhoneNumberClick(t("app.adresDesc22number"))}> {t("app.adresDesc22number")}</span> 
</div>  
<span> {t("app.adresDesc22")}</span> 
          </div>
        </div>


        <div className="contact-adres-area">
          <div  className="contact-adres">
            <h3 className="contact-adres-area-title">{t("app.adresTitle3")}</h3>
            <div  className="contact-adres-area-desc">{t("app.adresDesc3")}</div>
            <br/>
            <div  className="contact-adres-area-desc"><b>{t("app.contactDetails")}</b></div> 
            <div  className="contact-adres-area-desc">
           <span> {t("app.phone")}</span>
            <span  className="contact-adres-area-desc-phone" onClick={() => handlePhoneNumberClick(t("app.adresDesc33number"))}>    {t("app.adresDesc33number")}</span> 
          <span> {t("app.adresDesc33")}</span> 
            </div> 
          </div>

          <div  className="contact-adres">
            <h3 className="contact-adres-area-title">{t("app.adresTitle4")}</h3>
            <div  className="contact-adres-area-desc">{t("app.adresDesc4")}</div>
            <br/>
            <div  className="contact-adres-area-desc"><b>{t("app.contactDetails")}</b></div> 
            <div  className="contact-adres-area-desc"   >
           <span>{t("app.phone")}</span> 
          <span  className="contact-adres-area-desc-phone" onClick={() => handlePhoneNumberClick(t("app.adresDesc44number"))}> {t("app.adresDesc44number")}</span> 
             <span> {t("app.adresDesc44")}</span>
              </div> 
          </div>
        </div>

        
    </div>
    <Newsletter />
    <Footer />
   
  </>   
  );
};

export default Contact;
