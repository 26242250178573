import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import 'firebase/database'; // Database kullanacaksanız
import 'firebase/auth'; // Authentication kullanacaksanız
import 'firebase/storage'; // Depolama kullanacaksanız
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getFirestore,collection,addDoc } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBcGEHTRCy0M_fCQ33lrqlQIOBHSfH1VVQ",
  authDomain: "tigsy-185d5.firebaseapp.com",
  databaseURL: "https://tigsy-185d5-default-rtdb.firebaseio.com",
  projectId: "tigsy-185d5",
  storageBucket: "tigsy-185d5.appspot.com",
  messagingSenderId: "494936447185",
  appId: "1:494936447185:web:c7370b35299cb6c27c8df0",
  measurementId: "G-40ZZ52WE24"
};

 const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const dbstore = getFirestore(app);


function Newsletter() {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const [emailList, setEmailList] = useState([]);
   
   useEffect(() => {
      initializeApp(firebaseConfig);
    }, []);
  
   
    
    useEffect(() => {
      const emailRef = ref(db, "emails");
      onValue(emailRef, (snapshot) => {
        const emails = snapshot.val();
        const emailList = [];
        for (let id in emails) {
          emailList.push({ id, ...emails[id] });
        }
        setEmailList(emailList);
      });
    }, []);
  
    const handleChange = (event) => {
      setEmail(event.target.value);
    };
    
    const addEmailStore = async () => {
      if (email) {
        try {
          await addDoc(collection(dbstore, "emails"), { email });
          setEmail("");
        } catch (error) {
          console.error("Error adding email: ", error);
        }
      }
    };

  return (
    <div>
       
        <div className="newsletter-area">
        <div className="newsletter-text-area">
                <p className="newsletter-text-bold"> {t("app.newsletterMessage1")}</p>  
                <p className="newsletter-text-message"> {t("app.newsletterMessage2")}</p>  
            </div>
      <div>
        <input
          className="newsletter-input"
          name="email"
          type="email"
          placeholder={t("app.yourEmail")}
          value={email}
          onChange={handleChange}
        />
        <button 
          
          className="newsletter-button" onClick={addEmailStore}>{t("app.newsletterSend")}</button>
      </div>
    </div>
    
    </div>
  );
}

export default Newsletter;

