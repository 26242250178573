import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import LogIn from './components/pages/LogIn';
import Home from "./components/pages/Home";
import Navbars from './components/Navbar';
import About from "./components/pages/AboutUs";
import Contact from "./components/pages/Contact";
import SendMail from "./components/pages/SendMail";
import PreviousWork from './components/pages/PreviousWork';
import Vision from './components/pages/Vision';
import Ceo from './components/pages/Ceo';

function App() {
  return (
    <>
      <Router>
        <Navbars />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/login' component={LogIn} />
          <Route path='/about' component={About} />
          <Route path='/contact' component={Contact} />
          <Route path='/previouswork' component={PreviousWork} />
          <Route path='/sendmail' component={SendMail} />
          <Route path='/vision' component={Vision} />
          <Route path='/ceo' component={Ceo} />

        </Switch>
      </Router>
    </>
  );
}

export default App;
