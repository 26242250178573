import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
 import '../../App.css';
import Footer from "../Footer";

const loginUrl = "https://4jpf41orag.execute-api.us-east-1.amazonaws.com/prod/login";

function LogIn(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState(null);

  const { t } = useTranslation();

  const submitHandler = (event) => {
    event.preventDefault();
    if (email.trim() === "") {
      return;
    }
    setMessage(null);
     const requestConfig = {
      headers: {
        "x-api-key": "Qy2mu0yTyO4G6blfPiCsJ1NsLgxqSCV719SUyfME",
      },
    };
    const requestBody = {
      email: email  ,
      password: password
    };
    axios
    .post(loginUrl, requestBody, requestConfig)
    .then((response) => {
      console.log("response",response);
      if (
        response.data.success === false 
      ) {
        setMessage(response.data.message);
       
      } else {
        setMessage(response.data.message);
        props.history.push("/sendmail");
      
      }
    }) 
  setMessage(null);    
  };
  

  return (
    <> 
    <section  id="login">
      <div className="login-area">
        <h1  className="login-title-area">{t("app.adminlogin")}</h1>
          <form onSubmit={submitHandler}>
            <input
             className="login-input"
              name="email"
              type="email"
              placeholder={t("app.yourEmail")}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <br />
             <input
             className="login-input"
              name="password"
              type="password"
              placeholder={t("app.yourPassword")}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
             <br />
            <input
              type="submit"
              value={t("app.send")}
              className="login-button"
             
            />
          </form>
          {message && <p className="message">{message}</p>}   
      </div>  
     
      <Footer />
      </section>
    </> 
  );
}

export default LogIn;
