import React from "react";
import "../../App.css";
import HeroSection from "../HeroSection";
import HomeComponents from "./HomeComponents";


function Home() { 
  return (
    <>  
      <HeroSection />
      <HomeComponents/>  
   </>
  );
}

export default Home;
