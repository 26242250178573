import React, { useState, useEffect,useRef } from "react";
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import Logo from "../images/tigaya-logo.png";

const Sticky2Styled = styled.div`
  overflow: hidden;
  .sticky{
    height: 100vh;
    width: 100%;
    & .animation, .animation2 {
      width: 100%;
      height: 100%;
      position: absolute;
     
    }
    
  }
`;

const Sticky = () => {
    const [newwidth, setnewWidth] = useState("550");
    const [newHeight, setnewHeight] = useState("450");


    const navRef = useRef();
    navRef.current = newwidth
    useEffect(() => {
      const handleScroll = () => {
       //console.log("window.scrollY",window.scrollY);
       //console.log("WİTDH",450-(window.scrollY)*2);
        const show = window.scrollY > 200
        if (navRef.current !== show) {
            setnewWidth(550-(window.scrollY)*2)
            setnewHeight(450-(window.scrollY)*1.7)
        }
      }
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }, [])



return (
  <Sticky2Styled>
    <div className="section" />
    <Controller>
      <div>
        <Scene
          triggerHook="onLeave"
          duration={1000}
          pin
        >
          {(progress) => (
            <div className="sticky">
              <Timeline totalProgress={progress} paused>
                <Tween
                    from={{ x: 0, top: '70%' }}
                    to={{ x: -110, top: '-20%' }}
                >
                  <div className="animation">
                  <img src={Logo} width={newwidth} height={newHeight}  alt="" />
                   
                  </div>
                </Tween>
                
              </Timeline>
            </div>
          )}
        </Scene>
      </div>
    </Controller>
    <div className="section" />
  </Sticky2Styled>
);
}
export default Sticky;