import React, { useState, useEffect,useRef } from "react";
import { useTranslation } from "react-i18next";
 import '../../App.css';
import Footer from "../Footer";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import emailjs from '@emailjs/browser';


function SendMail() {
  const [mail, setMail] = useState("");
  const [subject, setSubject] = useState("");

  const [emailList, setEmailList] = useState([]);
  const [message, setMessage] = useState(null);

  const { t } = useTranslation();


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4nesbds', 'template_hsbcgnv', form.current, 'RdDO8vkek1GZgWZwI')
      .then((result) => {
          console.log(result.text);
          console.log("gönderildi");
      }, (error) => {
          console.log(error.text);
      });
  };

  
  const getEmailList = async () => {
    const db = getFirestore();
    const emailsRef = collection(db, "emails");
    const emailSnapshot = await getDocs(emailsRef);
    const emailList = emailSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEmailList(emailList);
  
  };

  useEffect(() => {
    getEmailList();
  }, []);


  return (
    <> 
      <div className="login-area">
      <form ref={form} onSubmit={sendEmail}>
          
            <h1>{t("app.mailList")}</h1>
           
            {emailList.map((email) => (
        <li><input type="email" name="user_email" value={email.email} className="mail-list-items"/></li>  
        ))}

            <h3>{t("app.mailcontent")}</h3>
            <input
             className="login-input"
              name="subject"
              type="textarea"
              placeholder={t("app.subject")}
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
            />
            <br />
            <input
             className="login-input"
              name="message"
              type="textarea"
              placeholder={t("app.yourMessage")}
              value={mail}
              onChange={(event) => setMail(event.target.value)}
            />
            <br />
           
            <input type="submit" value={t("app.send")}  className="login-button"/>
          </form>
          {message && <p className="message">{message}</p>}   
      </div>  
     
      <Footer />
    </> 
  );
}

export default SendMail;







