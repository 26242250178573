import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import React, { useState ,useEffect} from "react";
import { useTranslation } from "react-i18next";
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Newsletter from "./Newsletter";
import { HashLink } from 'react-router-hash-link';

const SectionWipesStyled = styled.div`
  overflow: hidden;
  .panel {
    height: 100vh;
    width: 100vw;
    text-align: center;
  }
  
  .panel span {
    position: relative;
    display: block;
    overflow: visible;
    top: 50%;
    font-size: 80px;
  }
  
  .panel.gray {
    background-color: #4B4F52;
    

  }
  
  .panel.turqoise {
    background-color: #38ced7;
  }
  
  .panel.green {
    background-color: #22d659;
    margin-bottom: 800px;
  }
  
  .panel.bordeaux {
    background-color: #953543;
  }
`;

const SectionWipes = () => {
  const [isActiveAgri, setIsActiveAgri] = useState(false);
  const [isActiveReal, setIsActiveReal] = useState(false);
  const [isActiveMedical, setIsActiveMedical] = useState(false);


  const handleClickAgri = () => { 
    setIsActiveAgri(true);
  
    if (!isActiveAgri) {
      document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
  } else {
      document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
  }
  };

  const handleClickReal = () => { 
    setIsActiveReal(true);
   
    if (!isActiveReal) {
        document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
    } else {
        document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
    }
  };
  
  const handleClickMedical = () => { 
    setIsActiveMedical(true);
   
    if (!isActiveMedical) {
      document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
  } else {
      document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
  }
  };

  const { t } = useTranslation();
 


  const handlePopoverCloseReal = () => {
    setIsActiveReal(current => !current);


    if (!isActiveReal) {
      document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
  } else {
      document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
  }
};

const handlePopoverCloseAgri = () => {
setIsActiveAgri(current => !current);

if (!isActiveAgri) {
  document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
} else {
  document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
}
};
const handlePopoverCloseMedical = () => {
setIsActiveMedical(current => !current);

if (!isActiveMedical) {
document.body.style.overflow = 'hidden'; // kaydırmayı devre dışı bırak
} else {
document.body.style.overflow = 'auto'; // kaydırmayı tekrar etkinleştir
}
};

 
  const popoverAgriculture = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" >{t("app.agriculture")}</Popover.Header>
      <Popover.Body >
      <div className="text" ><img src="/images/x.svg" alt="" width={30} height={30}/></div>
      {t("app.agricultureDesc")}
      </Popover.Body>
    </Popover>
  );

  

  
  const popoverRealestate = (
    <Popover id="popover-basic" >
      <Popover.Header as="h3" >{t("app.realEstate")}</Popover.Header>
      <Popover.Body >
      <div className="text" ><img src="/images/x.svg" alt="" width={30} height={30}/></div>
      
      {t("app.realEstateDesc")}
      </Popover.Body>
    </Popover>
  );
  
  
  const popoverMedical = (
    <Popover id="popover-basic" >
      <Popover.Header as="h3"   >{t("app.medical")}</Popover.Header>
      <Popover.Body >
      <div className="text"  ><img src="/images/x.svg" alt="" width={30} height={30}/></div>
      {t("app.medicalDesc")}
      </Popover.Body>
    </Popover>
  );
 
/*
  useEffect(() => {
 
  }, [isActiveReal,isActiveMedical,isActiveAgri]);
  */
  

return (
    <>
    <SectionWipesStyled>
        <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
        <Scene pin>
            <div className="panel">
            <div className="text-center home-gallery-area2">
      <div
        className={
          isActiveReal ? "blur home-gallery-img real-estate " : "normal home-gallery-img real-estate"
        }
      >
        <div className="real-estate-action">
          <h1 className="home-gallery-area-header" style={{ opacity: isActiveReal ? "0" : "1" }}>
          {t("app.realEstate")}
          </h1>
          <OverlayTrigger
            trigger="click"
            placement="top-start"
            overlay={popoverRealestate}
            delay="2"
            onExited={handlePopoverCloseReal}
            rootClose
          >
            <Button className="home-gallery-area-button" onClick={handleClickReal}>
            {t("app.discover")}
            </Button>
          </OverlayTrigger>
        </div>
      </div>
    </div>
          </div>
          </Scene>
          <Scene pin>
            <div className="panel">
            <div className="text-center home-gallery-area2">
                <div  className={isActiveAgri ? "blur home-gallery-img agriculture": "normal home-gallery-img agriculture"}>
                <div className="agriculture-action">
                    <h1 className="home-gallery-area-header "  style={{ opacity: isActiveAgri ? '0' : '1' }}> {t("app.agriculture")}</h1>
                    <OverlayTrigger trigger="click" placement="top-start" overlay={popoverAgriculture}  onExited={handlePopoverCloseAgri}
            rootClose delay="2">
                      <Button  className="home-gallery-area-button"
                          onClick={handleClickAgri} >{t("app.discover")}</Button>
                    </OverlayTrigger>
                    </div>
                </div>
         </div>
            </div>
          </Scene>         
          <Scene pin>
            <div className="panel">

         <div className="text-center home-gallery-area2">
                <div   className={isActiveMedical ? "blur home-gallery-img medical": "normal home-gallery-img medical"}>
                <div className="medical-action">
                    <h1 className="home-gallery-area-header "  style={{ opacity: isActiveMedical ? '0' : '1' }}> {t("app.medical")}</h1>
                    <OverlayTrigger trigger="click" placement="top-start" overlay={popoverMedical}  onExited={handlePopoverCloseMedical}
            rootClose delay="2">
                      <Button  className="home-gallery-area-button"
                          onClick={handleClickMedical} >{t("app.discover")}</Button>
                    </OverlayTrigger>
                    </div>
                </div>
         </div>
            </div>
          </Scene>

          <Scene pin>
        <div className="panel gray">
        <Newsletter />
        <div className="footer-container-home" id="footer">
      <form >
        <div className="footer-area-home">

        <div className="footer-area-action"  style={{textAlign:"left"}}>
          <HashLink smooth to='/#welcome' className="footer-area-link" > <h6 className="footer-area-title">{t("app.home")}</h6></HashLink> 
        </div>

        <div className="footer-area-action"  style={{textAlign:"left"}}>
         
          <HashLink smooth  className="footer-area-link" > <h6 className="footer-area-title">{t("app.about")}</h6></HashLink>
          <HashLink smooth to='/ceo/#ceo' className="footer-area-link" ><p className="footer-area-link-a">{t("app.ceoMessage")}</p></HashLink>
          <HashLink smooth to='/vision/#misionvision' className="footer-area-link" ><p className="footer-area-link-a">{t("app.visionMission")}</p></HashLink>
          <HashLink smooth to='/about/#about' className="footer-area-link" ><p className="footer-area-link-a">{t("app.history")}</p></HashLink>
          </div>

          <div className="footer-area-action"  style={{textAlign:"left"}}>
          <HashLink smooth to='/contact/#contact' className="footer-area-link" >  <h6 className="footer-area-title">{t("app.contactUs")}</h6></HashLink>
          <HashLink smooth to='/contact/#map' className="footer-area-link" > <p className="footer-area-link-a"> {t("app.map")}</p> </HashLink>
          <HashLink smooth to='/contact/#address' className="footer-area-link" ><p className="footer-area-link-a">{t("app.contactInfo")}</p></HashLink>
          </div>
        
         
              
          <div className="footer-area-action"  style={{textAlign:"left"}}>
          <HashLink smooth to='/previouswork/#previouswork' className="footer-area-link" >  <h6 className="footer-area-title">{t("app.companies")}</h6></HashLink>
          <a
              className="footer-area-link"
              href="https://smougroup.ae"
            ><p className="footer-area-link-a">{t("app.alsmoureal")}</p></a>
          <a
              className="footer-area-link"
              href="http://www.royalgrandhotel.ae"
            ><p className="footer-area-link-a">{t("app.royal")}</p></a>
            <a
              className="footer-area-link"
              href="https://ahkam.ae"
            >
              <p className="footer-area-link-a">{t("app.ahkam")}</p></a>
          </div>

          
          <div className="footer-area-action"  style={{textAlign:"left"}}>
          <HashLink smooth to='/login/#login' className="footer-area-link" > <h6 className="footer-area-title">{t("app.adminPanel")}</h6></HashLink>
          <HashLink smooth to='/login/#login' className="footer-area-link" > <p className="footer-area-link-a">{t("app.login")}</p></HashLink>
          </div>
      
        </div>
    </form>
    </div>        </div>
          </Scene>
        </Controller>
    </SectionWipesStyled>
     
    
    </>
);
};

export default SectionWipes;