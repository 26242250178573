import React, { useState,useEffect } from "react";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import Newsletter from "./Newsletter";


const PreviousWork = () => {
  const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  return (
  <>
    <div id="previouswork" >
    {windowSize[0] > 500 ?  
    <div className="previous-page"> 
        <div className="previous-image">
        <h1 className="about-title">{t("app.previousWork")}</h1>
        </div>
      
      <div className="previous-all-area">
      


        <div className="text-area-work previous-work-smou" >
          <div className="text-area-work-half"><img src="/images/previousWorkAlSmou.png" alt="" className="previous-logo-smou"  /></div> 
          <div className="text-area-work-half-text"
          ><p >{t("app.previousWorkDescAlSmou")}</p>
          <a href="https://smougroup.ae/comp_real_estate.php" className="previous-link"><p>https://smougroup.ae/comp_real_estate.php</p></a>
</div>
        </div>
        


        <div className="text-area-work previous-work-damas" >
           <div className="text-area-work-half-text"> <p >{t("app.previousWorkDescDamas")}</p>
           <a href="https://smougroup.ae/comp_damas.php" className="previous-link"><p>https://smougroup.ae/comp_damas.php</p></a>
</div>
           <div className="text-area-work-half"><img src="/images/previousWorkDamas.png" alt="" className="previous-logo-damas"  /></div> 
        </div>
      

        <div className="text-area-work previous-work-royal">
          <div className="text-area-work-half"> <img src="/images/previousWorkRoyal.png" alt="" className="previous-logo-royal"  />
          </div> 
        
            <div  className="text-area-work-half-text">
              <p >{t("app.previousWorkDescRoyal")}</p>
              <a href="http://www.royalgrandhotel.ae" className="previous-link"><p>http://www.royalgrandhotel.ae</p></a>
            </div>
        
        </div>


        <div className="text-area-work previous-work-ahkam"  > 
              <div  className="text-area-work-half-text">
                <p >{t("app.previousWorkDescAhkam")}</p>
                <a href=" https://www.ahkam.ae" className="previous-link"><p> https://www.ahkam.ae</p></a>
              </div>
           
          <div className="text-area-work-half"> <img src="/images/previousWorkAhkam.png" alt="" className="previous-logo-ahkam"  /></div> 
        </div>
        </div> 
    </div>
    : 
    <div className="previous-page"> 
    <div className="previous-image">
    <h1 className="about-title">{t("app.previousWork")}</h1>
    </div>
  
    


    <div className="about-ceo-area previous-area2" >
      <div>
        <img src="/images/previousWorkAlSmou.png" alt="" className="previous-logo-smou"  />
      </div> 
      <div className="previous-logo-desc">
        <div className="previous-desc">
          <p >{t("app.previousWorkDescAlSmou")}</p>
          <a href="https://smougroup.ae/comp_real_estate.php" className="previous-link"><p>https://smougroup.ae/comp_real_estate.php</p></a>

        </div>
      </div>
    </div>
    <hr/>


    <div className="about-ceo-area previous-area2" >
    <div>
        <img src="/images/previousWorkDamas.png" alt="" className="previous-logo-damas"  />
      </div> 
    <div className="previous-logo-desc">
        <div className="previous-desc">
          <p >{t("app.previousWorkDescDamas")}</p>
          <a href="https://smougroup.ae/comp_damas.php" className="previous-link"><p>https://smougroup.ae/comp_damas.php</p></a>

        </div>
      </div>
  
    </div>
    <hr/>


    <div className="about-ceo-area previous-area2" >
      <div>
        <img src="/images/previousWorkRoyal.png" alt="" className="previous-logo-royal"  />
      </div> 
      <div className="previous-logo-desc">
        <div className="previous-desc">
          <p >{t("app.previousWorkDescRoyal")}</p>
          <a href="http://www.royalgrandhotel.ae" className="previous-link"><p>http://www.royalgrandhotel.ae</p></a>
        </div>
      </div>
    </div>
    <hr/>


    <div className="about-ceo-area previous-area2" >
    <div>
        <img src="/images/previousWorkAhkam.png" alt="" className="previous-logo-ahkam"  />
      </div> 
    <div className="previous-logo-desc">
        <div className="previous-desc">
          <p >{t("app.previousWorkDescAhkam")}</p>
          <a href=" https://www.ahkam.ae" className="previous-link"><p> https://www.ahkam.ae</p></a>
        </div>
      </div>
      
    </div>
    
</div>
     }
  </div>
  <Newsletter />
    <Footer /> 
  </>
  );
};

export default PreviousWork;

