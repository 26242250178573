
import React, { useState, useEffect,useRef } from "react";
import "./Navbar.css";
import Logo from "../images/tigaya-logo.png";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { HashLink } from 'react-router-hash-link';
import NavDropdown from 'react-bootstrap/NavDropdown';


function Navbars() {
  const { t, i18n } = useTranslation(); 
  const [navBackground, setNavBackground] = useState(false);


  const [active, setActive] = useState(i18n.language);

  console.log("i18n.languageeeee",i18n.language)
  
  useEffect(() => {
    setActive(i18n.language);
  }, [i18n.language]);
  

  useEffect(() => {

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const handleClickLang = (event) => {
    setActive(event.target.id);
  };
  
  function clickLanguage(lang) {
    i18n.changeLanguage(lang);
  }
  
  const handleLinkClick = () => {
    document.querySelector(".navbar-collapse").classList.remove("show");
  }

 

  const navRef = useRef();
    navRef.current = navBackground
    useEffect(() => {
      const handleScroll = () => {
        const show = window.scrollY > 220
        if (navRef.current !== show ) {
          setNavBackground(show)
        }
      }
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }, [])

    const [windowSize, setWindowSize] = useState([
      window.innerWidth,
      window.innerHeight,
    ]);
   
  
   
 
  return (
    <>
     
     {windowSize[0] > 913 ?
    <Navbar  expand="lg" fixed="top"   className={navBackground ?  'navbar-black' : 'navbar-transparent'}  >
      <Container>
     <Navbar.Brand className={navBackground ?  'navbar-logo-action' : 'navbar-logo-first-action'}>
     <HashLink smooth to='/#welcome' >
          <img src={Logo} width="90" height="60" alt="" />
        </HashLink>    
        </Navbar.Brand>
        
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
       
      <Navbar.Collapse id="basic-navbar-nav" className="navbar-left">
          <Nav className="me-auto">
          <HashLink smooth to='/#welcome' className="navbar-link">{t("app.home")}</HashLink>
       
          <NavDropdown title={t("app.about")} id="basic-nav-dropdown" className="navbar-link-dropdown">
  <NavDropdown.Item as={HashLink} smooth to="/ceo#ceo">{t("app.ceoMessageTitle")}</NavDropdown.Item>
  <NavDropdown.Item as={HashLink} smooth to="/vision#misionvision">{t("app.visionMission")}</NavDropdown.Item>
  <NavDropdown.Item as={HashLink} smooth to="/about#about">{t("app.history")}</NavDropdown.Item>
</NavDropdown>          
        
          <HashLink smooth to='/previouswork/#previouswork' className="navbar-link" onClick={handleLinkClick}> {t("app.previousWork")}</HashLink> 
          <HashLink smooth to='/contact/#contact'  className="navbar-link">{t("app.contact")}</HashLink>     

          </Nav>
        </Navbar.Collapse>

       
        <Navbar.Collapse id="basic-navbar-nav" className="navbar-right">
          <Nav className="me-auto">
           
            <nav className="nav-item-select">
                <button
                  className={active === "en" ? "aactive" : ""}
                  id="en"
                  onClick={(e) => {
                    clickLanguage("en");
                    handleClickLang(e);
                  }}
                >
                  EN
                </button>
                <div className="navbar-divider">  </div>
                <button
                  className={active === "tr" ? "aactive" : ""}
                  id="tr"
                  onClick={(e) => {
                    clickLanguage("tr");
                    handleClickLang(e);
                  }}
                >
                  TR
                </button>
            </nav>  
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    : 
    <Navbar  expand="lg" fixed="top"   className={navBackground ?  'navbar-black' : 'navbar-black'} >
    <Container>
   <Navbar.Brand className=  'navbar-logo-action'  onClick={handleLinkClick}>
   <HashLink smooth to='/#welcome' >
        <img src={Logo} width="90" height="60" alt="" />
      </HashLink>    
      </Navbar.Brand>
      
    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
     
    <Navbar.Collapse id="basic-navbar-nav" className="navbar-left">
        <Nav className="me-auto">
        <HashLink smooth to='/#welcome' className="navbar-link" onClick={handleLinkClick}> {t("app.home")}</HashLink> 
        <NavDropdown title={t("app.about")} id="basic-nav-dropdown" className="navbar-link-dropdown" >
  <NavDropdown.Item as={HashLink} smooth to="/ceo#ceo"  onClick={handleLinkClick}>{t("app.ceoMessageTitle")} </NavDropdown.Item>
  <NavDropdown.Item as={HashLink} smooth to="/vision#misionvision" onClick={handleLinkClick}>{t("app.visionMission")}</NavDropdown.Item>
  <NavDropdown.Item as={HashLink} smooth to="/about#about" onClick={handleLinkClick}>{t("app.history")}</NavDropdown.Item>
        </NavDropdown>     
        <HashLink smooth to='/previouswork/#previouswork' className="navbar-link" onClick={handleLinkClick}> {t("app.previousWork")}</HashLink> 

        <HashLink smooth to='/contact/#contact'  className="navbar-link" onClick={handleLinkClick}>{t("app.contact")}</HashLink>     
     
        <nav className="nav-item-select">
                <button
                  className={active === "en" ? "aactive" : ""}
                  id="en"
                  onClick={(e) => {
                    clickLanguage("en");
                    handleClickLang(e);
                  }}
                >
                  EN
                </button>
                <div className="navbar-divider">  </div>
                <button
                  className={active === "tr" ? "aactive" : ""}
                  id="tr"
                  onClick={(e) => {
                    clickLanguage("tr");
                    handleClickLang(e);
                  }}
                >
                  TR
                </button>
            </nav> 
        </Nav>
      </Navbar.Collapse>

     
      
    </Container>
    </Navbar>
}
    </>
  );
}

export default Navbars;

