import React from "react";
import "../App.css";
import "./HeroSection.css";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Sticky from "./Sticky";

function HeroSection() {

  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  return (
    <>
      <div className="hero-container" id="welcome">
        <div className="left-side">
          <h1 className="home-title">{t("app.homeTitle")}</h1>
          <p className="home-desc">{t("app.homeDesc")}</p>
        </div>
        <div className="right-side"> 
        {windowSize[0] > 1150 ?     <Sticky />: <></> }
      
        </div>
      </div>   
    </>
  );
}

export default HeroSection;




