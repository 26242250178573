import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { HashLink } from 'react-router-hash-link';


function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer-container" id="footer">
      <form >
        <div className="footer-area">

        <div className="footer-area-action">
          <HashLink smooth to='/#welcome' className="footer-area-link" > <h6 className="footer-area-title">{t("app.home")}</h6></HashLink> 
        </div>

        <div className="footer-area-action">
         
          <div   className="footer-area-link" > <h6 className="footer-area-title">{t("app.about")}</h6></div>
          <HashLink smooth to='/ceo/#ceo' className="footer-area-link" ><p className="footer-area-link-a">{t("app.ceoMessage")}</p></HashLink>
          <HashLink smooth to='/vision/#misionvision' className="footer-area-link" ><p className="footer-area-link-a">{t("app.visionMission")}</p></HashLink>
          <HashLink smooth to='/about/#about' className="footer-area-link" ><p className="footer-area-link-a">{t("app.history")}</p></HashLink>

          </div>

         
        
         
              
          <div className="footer-area-action">
          <HashLink smooth to='/previouswork/#previouswork' className="footer-area-link" >  <h6 className="footer-area-title">{t("app.companies")}</h6></HashLink>

          <a
              className="footer-area-link"
              href="https://smougroup.ae"
            ><p className="footer-area-link-a">{t("app.alsmoureal")}</p></a>
          <a
              className="footer-area-link"
              href="http://www.royalgrandhotel.ae"
            ><p className="footer-area-link-a">{t("app.royal")}</p></a>
            <a
              className="footer-area-link"
              href="https://ahkam.ae"
            >
              <p className="footer-area-link-a">{t("app.ahkam")}</p></a>
          </div>
          <div className="footer-area-action" >
          <HashLink smooth to='/contact/#contact' className="footer-area-link" >  <h6 className="footer-area-title">{t("app.contactUs")}</h6></HashLink>
          <HashLink smooth to='/contact/#map' className="footer-area-link" > <p className="footer-area-link-a"> {t("app.map")}</p> </HashLink>
          <HashLink smooth to='/contact/#address' className="footer-area-link" ><p className="footer-area-link-a">{t("app.contactInfo")}</p></HashLink>
          </div>

          
          <div className="footer-area-action">
          <HashLink smooth to='/login/#login' className="footer-area-link" > <h6 className="footer-area-title">{t("app.adminPanel")}</h6></HashLink>
          <HashLink smooth to='/login/#login' className="footer-area-link" > <p className="footer-area-link-a">{t("app.login")}</p></HashLink>
          </div>
      
        </div>
    </form>
    </div>
  );
}

export default Footer;

