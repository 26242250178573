import React from "react";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import Newsletter from "./Newsletter";


const Vision= ()=> {
  const { t } = useTranslation();

  return (
    <section id="misionvision">
      <div className="vision-image">
        <h1 className="about-title">{t("app.visionMission")}</h1>
      </div>
     <div className="total-area">
     

      <div className="vision-area">
      <img src="/images/vector.png" alt="" width="50" height="120" className="about-vector"  />
      <h2 className="previous-title">{t("app.visionTitle")}</h2>
        <p  className="mv-text-area">{t("app.visionDesc")}</p>
      </div>
      <div className="mission-area">
      <img src="/images/vector.png" alt="" width="50" height="120" className="about-vector"  />
      <h2 className="previous-title">{t("app.missionTitle")}</h2>
        <p className="mv-text-area">{t("app.missionDesc")}</p>
      </div>
   
     </div> 
      

     <Newsletter />
      <Footer />

    </section>
    
  );
}

export default Vision;

